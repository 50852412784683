import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

import gifbg from '~src/assets/images/giphy.gif';
import { Link } from '~src/components/localized-link';
import Layout from '~src/layouts/page-layout';

const Wrapper = styled.div`
  height: 100vh;
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  flex-direction: column;
  top: 0;
  left: 0;
  padding: 2em;
  height: 100vh;
  width: 100%;
  z-index: 6;
  h1,
  p {
    color: #fff;
    text-align: center;
  }
  a {
    width: auto;
    outline: none;
    background: var(--color--primary-main);
    color: var(--color--text-secondary);
    border: none;
    margin-bottom: 15px;
    padding: 14px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      background: var(--color--primary-hover);
      color: var(--color--text-secondary);
    }
  }
`;

const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: url(${gifbg});
  background-size: cover;
  background-position: center;
  z-index: 1;
  &::after {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
  }
`;

const seoData = {
  title: '404: Not found',
};

const NotFoundPage: FC<PageProps> = ({ location }) => (
  <Layout pathname={location.pathname} seoData={seoData}>
    <Wrapper>
      <Image />
      <Text>
        <h1>Page not found</h1>
        <p>{location.href} doesn&apos;t exist or has been moved.</p>
        <Link to="/">Back to Home Page</Link>
      </Text>
    </Wrapper>
  </Layout>
);

export default NotFoundPage;
